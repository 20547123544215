import React, { Component } from 'react';

class atv extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
      <rect x="434" y="258.5" className="st32" width="89.5" height="32.5"/>
	<path className="st33" d="M517.9,279.5h-8.4l7.6-9.4c0.5-0.7,0.8-1.7,0.8-4.4h-13.2c0,2.7-0.3,3.7-0.8,4.4v0h7.2l-7.7,9.4
		c-0.6,0.9-0.9,1.6-0.9,4.5h15.3V279.5z M503.1,270c0.5-0.7,0.8-1.7,0.8-4.4h-19.7v11c0,1.8-0.8,2.9-2.8,2.9c-2,0-2.8-1.2-2.8-2.9
		v-11h-7.3v2.9c-1-1.8-2.9-2.9-5.7-2.9h-10.1v2.4c-1-1.5-2.9-2.4-5.8-2.4h-9.9v4.1h2v9.9h-2v4.1h10c4.1,0,6.9-1.8,6.9-5.3
		c0-2.2-1.4-3.5-3.3-4v-0.1c1.4-0.4,2.7-1.7,2.7-4.1c0-0.2,0-0.4,0-0.6h1.4v14h5.4v-6.5h1.4l2.4,3.8c1.6,2.6,3,2.9,5.2,2.9
		c0.6,0,1.1-0.1,1.6-0.3v-3.8c-0.2,0-0.4,0-0.5,0c-0.6,0-1.1-0.2-1.6-0.9l-1.9-2.8c1.6-1,2.5-2.7,2.5-4.9c0-0.6-0.1-1.2-0.2-1.7h1.5
		v6.9c0,5.3,3.2,7.3,8.2,7.3c5,0,7.8-2,7.8-7.3V270h8l-7.6,9.3c-0.6,0.9-0.9,1.6-0.9,4.4h13c0-2.8,0.3-3.5,0.9-4.4h-7.1L503.1,270
		L503.1,270z M450.6,271.4c0,1-0.5,1.6-1.9,1.6H447v-3.1h1.8C450.1,269.9,450.6,270.4,450.6,271.4z M450.6,277.8
		c0,1.2-0.7,1.8-1.9,1.8H447V276h1.8C449.9,276,450.6,276.6,450.6,277.8L450.6,277.8z M466.5,271.7c0,1-0.6,1.8-1.9,1.8h-1.8v-3.7
		h1.8C466,269.9,466.5,270.7,466.5,271.7L466.5,271.7z"/></g>
    );
  }
}

export default atv;