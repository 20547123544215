import React, { Component } from 'react';

var imgStyle = {
  overflow:'visible',
};

class wtv extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
	<path className="st25" d="M161.4,218.1H82.6c-4.2,0-7.6-3.4-7.6-7.6v-38.2c0-4.2,3.4-7.6,7.6-7.6h78.9c4.2,0,7.6,3.4,7.6,7.6v38.2
		C169,214.8,165.6,218.1,161.4,218.1z"/>
	<g>
		<defs>
			<rect id="SVGID_16_" x="63.3" y="150.8" width="117.6" height="83.1"/>
		</defs>
		<clipPath id="SVGID_17_">
			<use xlinkHref="#SVGID_16_"  style={imgStyle}/>
		</clipPath>
		<path className="st26" d="M125.9,184V180h-4.4c-1.8,0-3.3,1.5-3.3,3.3v4.1h4.4C124.4,187.3,125.9,185.8,125.9,184"/>
		<path className="st27" d="M130.7,172.7h-17.3c-2.2,0-3.9,1.9-3.9,4.2v13.5c0,2.3,1.7,4.2,3.9,4.2h17.3c2.2,0,3.9-1.9,3.9-4.2v-13.5
			C134.6,174.6,132.9,172.7,130.7,172.7 M133.3,189.9c0,1.8-1.5,3.3-3.3,3.3h-8.5c-1.8,0-3.3-1.5-3.3-3.3v-2.6h-4.1
			c-1.8,0-3.3-1.5-3.3-3.3v-6.7c0-1.8,1.5-3.3,3.3-3.3h8.5c1.8,0,3.3,1.5,3.3,3.3v2.6h4.1c1.8,0,3.3,1.5,3.3,3.3V189.9z"/>
		<path className="st26" d="M86.5,204.9h3.3v1.6h-3.3v3.8h-1.8v-6.3c0-1.8,1-2.9,2.9-2.9h2.8v1.7h-2.8c-0.8,0-1.2,0.3-1.2,1.2V204.9z"/>
		<path className="st26" d="M100.3,205.7c0,2.7-1.9,4.8-4.5,4.8c-2.6,0-4.5-2.1-4.5-4.8c0-2.7,1.9-4.8,4.5-4.8
			C98.4,200.9,100.3,203,100.3,205.7 M98.5,205.7c0-1.7-1.2-3.1-2.7-3.1c-1.5,0-2.8,1.4-2.8,3.1c0,1.7,1.2,3.1,2.8,3.1
			C97.3,208.8,98.5,207.4,98.5,205.7"/>
		<path className="st26" d="M105.7,210.5c-2.5,0-4.5-2.1-4.5-4.8s2-4.8,4.5-4.8c2.1,0,3.6,1.2,4.2,3.1H108c-0.4-0.8-1.2-1.5-2.3-1.5
			c-1.5,0-2.7,1.5-2.7,3.2c0,1.8,1.2,3.2,2.7,3.2c1,0,1.9-0.7,2.3-1.5h1.9C109.3,209.3,107.7,210.5,105.7,210.5"/>
		<path className="st26" d="M111.9,209.6c-0.6-0.6-0.9-1.5-0.9-2.4v-6h1.8v6.1c0,1,0.6,1.6,1.6,1.6c1,0,1.6-0.6,1.6-1.6v-6.1h1.8v6
			c0,0.9-0.3,1.8-0.9,2.4c-0.6,0.6-1.5,0.9-2.4,0.9C113.4,210.5,112.5,210.2,111.9,209.6"/>
		<path className="st26" d="M123.3,203.3c-0.5,0.4-0.8,1-0.8,2.5c0,1.3-0.2,2.8-1.4,3.8c-0.7,0.6-1.7,0.9-2.9,0.9v-1.7
			c0.6,0,1.3-0.1,1.8-0.5c0.4-0.4,0.7-1,0.7-2.4c0-1.8,0.4-2.9,1.4-3.7c0.7-0.6,1.6-0.9,2.8-0.9v1.7
			C124.2,202.8,123.6,203,123.3,203.3"/>
		<rect x="128" y="198" className="st27" width="0.3" height="14"/>
		<path className="st26" d="M143.1,207.2c0,1.8-1.4,3.3-3.3,3.3c-1,0-1.9-0.5-2.5-1.2c-0.6,0.7-1.4,1.2-2.5,1.2c-2,0-3.3-1.5-3.3-3.3
			v-6.1h1.7v6.1c0,0.9,0.6,1.6,1.6,1.6c1,0,1.6-0.7,1.6-1.6v-6.1h1.8v6.1c0,0.9,0.6,1.6,1.6,1.6c1,0,1.6-0.7,1.6-1.6v-6.1h1.8V207.2
			z"/>
		<polygon className="st26" points="148.4,202.8 148.4,210.3 146.6,210.3 146.6,202.8 144.1,202.8 144.1,201.1 150.8,201.1 150.8,202.8 
					"/>
		<polygon className="st26" points="156.3,210.3 154.4,210.3 151.4,201.1 153.3,201.1 155.4,208.2 157.5,201.1 159.3,201.1 		"/>
	</g>
</g>

    );
  }
}

export default wtv;