import React, { Component } from 'react';

var divStyle = {
  stopColor: '#94D1D6',
};

var divStyle2 = {
  stopColor: '#B0DDE2',
};

var divStyle3 = {
  stopColor: '#DBE8ED',
};

var imgStyle = {
  overflow:'visible',
};

class atv extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
	
      <radialGradient id="SVGID_1_" cx="525.995" cy="97.7984" r="35.5949" gradientTransform="matrix(0.64 0.7683 -0.6912 0.5758 256.9289 -362.6511)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style={divStyle}/>
		<stop  offset="0.5079" style={divStyle2}/>
		<stop  offset="1" style={divStyle3}/>
	</radialGradient>
	<path className="st1" d="M543.5,112.9c-0.3-1.5-0.2-3.2,1.5-5.1c4.4-4.5,7.1-10.2,7.1-16.4c0-14.2-13.8-25.8-30.8-25.8
		c-17,0-30.8,11.5-30.8,25.8c0,11.6,9.1,21.4,21.7,24.6c0,0,7.5,2.3,17.5,0.2c1.1-0.3,2.2-0.6,3.3-0.9c2.6-0.7,4.5,0.1,5.7,1
		l0.2,0.1c0,0,0,0,0,0l0,0l1.7,1.4l5,4l-1.4-6.2L543.5,112.9z"/>
	<g className="st2">
		<defs>
			<path id="SVGID_2_" className="st2" d="M543.4,112.7c-0.3-1.5-0.2-3.2,1.5-5.1c4.4-4.5,7.1-10.2,7.1-16.4c0-14.2-13.8-25.8-30.8-25.8
				c-17,0-30.8,11.5-30.8,25.8c0,11.6,9.1,21.4,21.7,24.6c0,0,7.5,2.3,17.5,0.2c1.1-0.3,2.2-0.6,3.3-0.9c2.6-0.7,4.5,0.1,5.7,1
				l0.2,0.1c0,0,0,0,0,0l0,0l1.7,1.4l5,4l-1.4-6.2L543.4,112.7z"/>
		</defs>
		<clipPath id="SVGID_3_">
			<use xlinkHref="#SVGID_2_"  style={imgStyle}/>
		</clipPath>
		<g className="st3">
			
				<image style={imgStyle} width="1316" height="1371" xlinkHref={require('../../assets/35EF3E805A050906.png')} transform="matrix(4.156487e-02 0 0 4.156487e-02 506.3691 66.3281)">
			</image>
		</g>
	</g>
	<g>
		
			<image style={imgStyle} width="980" height="488" xlinkHref={require('../../assets/35EF3E805A05090A.png')} transform="matrix(4.156487e-02 0 0 4.156487e-02 501.8391 88.6692)">
		</image>
	</g>
	<path className="st4" d="M492.9,83.4c11-15.6,36.7-21.8,54.4-3.7l-0.7,4.3c0,0-8.5-9.1-24.7-9.1c-16.9,0.1-20.6,5.5-26.2,10.9
		L492.9,83.4z"/>
	<g>
		<g>
			<g>
				<path className="st5" d="M512.1,85.8c-1-0.5-2.1-0.7-3.3-0.7c-3.9,0-7.1,3.2-7.1,7.2c0,4,3.2,7.2,7.1,7.2c1.2,0,2.3-0.2,3.3-0.7v0.4
					h3.7V85.4h-3.7V85.8z M512.1,94.5c-0.8,1-1.8,1.5-3,1.5c-2,0-3.6-1.6-3.6-3.7c0-2,1.6-3.7,3.6-3.7c1.2,0,2.3,0.5,3,1.5V94.5z"/>
				<path className="st5" d="M537.5,85.3c-0.6,0-1.1,0.1-1.4,0.3c-0.4,0.2-0.6,0.6-0.8,1.1l-2.8,8.1l-2.7-8.1c-0.2-0.5-0.5-0.9-0.8-1.1
					c-0.3-0.2-0.8-0.3-1.4-0.3h-2.6h-0.4h-2.4v-3.7h-3.9v3.7h-1.8v3.3h1.7v5.5c0,0.9,0.1,1.7,0.4,2.4c0.3,0.7,0.7,1.2,1.1,1.6
					c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.1-0.1l0.2,0v-3.5l-0.9,0.1
					c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.3-0.7-0.9-0.7-1.7v-5.1h2.4v0l0.3,0h0.2v0l1,0c0.2,0,0.4,0.1,0.5,0.3l3.9,10.5h2.7
					c0.6,0,1.1-0.1,1.4-0.3c0.4-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.3c0.1-0.2,0.2-0.3,0.5-0.3h1.6v-3.4H537.5z"/>
			</g>
		</g>
		<defs>
			<filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="501.7" y="81.7" width="38.8" height="17.8">
				
					<feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" colorInterpolationFilters="sRGB" result="source"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="501.7" y="81.7" width="38.8" height="17.8" id="SVGID_4_">
			<g className="st6">
				
					<image style={imgStyle} width="44" height="23" xlinkHref={require('../../assets/35EF3E805A05090A.jpg')}  transform="matrix(1 0 0 1 499.3168 78.7925)">
				</image>
			</g>
		</mask>
		<g className="st7">
			<g>
				<path className="st8" d="M512.1,85.8c-1-0.5-2.1-0.7-3.3-0.7c-3.9,0-7.1,3.2-7.1,7.2c0,4,3.2,7.2,7.1,7.2c1.2,0,2.3-0.2,3.3-0.7v0.4
					h3.7V85.4h-3.7V85.8z M512.1,94.5c-0.8,1-1.8,1.5-3,1.5c-2,0-3.6-1.6-3.6-3.7c0-2,1.6-3.7,3.6-3.7c1.2,0,2.3,0.5,3,1.5V94.5z"/>
				<path className="st8" d="M537.5,85.3c-0.6,0-1.1,0.1-1.4,0.3c-0.4,0.2-0.6,0.6-0.8,1.1l-2.8,8.1l-2.7-8.1c-0.2-0.5-0.5-0.9-0.8-1.1
					c-0.3-0.2-0.8-0.3-1.4-0.3h-2.6h-0.4h-2.4v-3.7h-3.9v3.7h-1.8v3.3h1.7v5.5c0,0.9,0.1,1.7,0.4,2.4c0.3,0.7,0.7,1.2,1.1,1.6
					c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.1-0.1l0.2,0v-3.5l-0.9,0.1
					c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.3-0.7-0.9-0.7-1.7v-5.1h2.4v0l0.3,0h0.2v0l1,0c0.2,0,0.4,0.1,0.5,0.3l3.9,10.5h2.7
					c0.6,0,1.1-0.1,1.4-0.3c0.4-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.3c0.1-0.2,0.2-0.3,0.5-0.3h1.6v-3.4H537.5z"/>
			</g>
		</g>
	</g>
	<g className="st9">
		<defs>
			<path id="SVGID_5_" className="st9" d="M537.5,85.3c-0.6,0-1.1,0.1-1.4,0.3c-0.4,0.2-0.6,0.6-0.8,1.1l-2.8,8.1l-2.7-8.1
				c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.2-0.8-0.3-1.4-0.3h-2.6h-0.4h-2.4v-3.7h-3.9v3.7h-1.8v3.3h1.7v5.5c0,0.9,0.1,1.7,0.4,2.4
				c0.3,0.7,0.7,1.2,1.1,1.6c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.1-0.1l0.2,0
				v-3.5l-0.9,0.1c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.3-0.7-0.9-0.7-1.7v-5.1h2.4v0l0.3,0h0.2v0l1,0c0.2,0,0.4,0.1,0.5,0.3l3.9,10.5h2.7
				c0.6,0,1.1-0.1,1.4-0.3c0.4-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.3c0.1-0.2,0.2-0.3,0.5-0.3h1.6v-3.4H537.5z"/>
		</defs>
		<clipPath id="SVGID_6_">
			<use xlinkHref="#SVGID_5_"  style={imgStyle}/>
		</clipPath>
		<g className="st10">
			
				<image style={imgStyle} width="870" height="887" xlinkHref={require('../../assets/35EF3E805A05090E.png')}  transform="matrix(4.156487e-02 0 0 4.156487e-02 517.1373 74.8142)">
			</image>
		</g>
	</g>

	</g>

    );
  }
}

export default atv;