import React, { Component } from "react";
import "./App.css";
import "es6-promise/auto";
import axios from "axios";
import Map from "./components/Map";
import Teasers from "./components/Teasers";
import Items from "./components/Items";
import Loading from "./components/Loading";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      items: [],
      itemsAll: [],
      itemsRingtv: [],
      itemsAtv: [],
      itemsTvl: [],
      itemsTvoost: [],
      itemsRobtv: [],
      itemsAVS: [],
      itemsFocusWTV: [],
      style: {},
      showAllButton: false,
      buttonProps: 0,
      styleSVGContainer: {},
      styleSVGMap: {}
    };
    this.allItems = this.allItems.bind(this);
    this.atvItems = this.atvItems.bind(this);
    this.tvlItems = this.tvlItems.bind(this);
    this.tvoostItems = this.tvoostItems.bind(this);
    this.robtvItems = this.robtvItems.bind(this);
    this.ringtvItems = this.ringtvItems.bind(this);
    this.avsItems = this.avsItems.bind(this);
    this.wtvItems = this.wtvItems.bind(this);
    this.rtvItems = this.rtvItems.bind(this);
    this.bruzzItems = this.bruzzItems.bind(this);
    this.onClickAlleItems = this.onClickAlleItems.bind(this);
  }

  componentWillMount() {
    axios({
      method: "get",
      url: "https://deburen.azurewebsites.net/nortv.json"
    }).then(res => {
      var alleItems = [
        // res.data[0][0],
        // res.data[0][1],
        // res.data[0][2],
        // res.data[0][3]
      ];
      alleItems.push(
        res.data[1][0],
        res.data[1][1],
        res.data[1][2],
        res.data[1][3]
      );
      alleItems.push(
        res.data[2][0],
        res.data[2][1],
        res.data[2][2],
        res.data[2][3]
      );
      alleItems.push(
        res.data[3][0],
        res.data[3][1],
        res.data[3][2],
        res.data[3][3]
      );
      alleItems.push(
        res.data[4][0],
        res.data[4][1],
        res.data[4][2],
        res.data[4][3]
      );
      // alleItems.push(res.data[5][0], res.data[5][1], res.data[5][2], res.data[5][3]);
      alleItems.push(
        res.data[6][0],
        res.data[6][1],
        res.data[6][2],
        res.data[6][3]
      );
      alleItems.push(
        res.data[7][0],
        res.data[7][1],
        res.data[7][2],
        res.data[7][3]
      );
      alleItems.push(
        res.data[8][0],
        res.data[8][1],
        res.data[8][2],
        res.data[8][3]
      );
      var shuffled = this.shuffle(alleItems);
      var Background = shuffled[0].thumbnail;
      this.setState({
        items: shuffled,
        itemsAll: this.shuffle(alleItems),
        // itemsAtv: res.data[0],
        itemsTvl: res.data[1],
        itemsTvoost: res.data[2],
        itemsRobtv: res.data[3],
        itemsRingtv: res.data[4],
        // itemsAVS: res.data[5],
        itemsFocusWTV: res.data[6],
        itemsRTV: res.data[7],
        itemsBruzz: res.data[8],
        loading: false,
        style: {
          backgroundImage:
            "linear-gradient(rgba(0,0,0,.4), rgba(0,0,0,.4)),url(" +
            Background +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "100%"
        }
      });
    });

    var version = this.detectIE();

    if (version === false) {
      this.setState({
        styleSVGContainer: {
          width: "50%",
          position: "relative"
        }
      });
    } else if (version >= 12) {
      this.setState({
        styleSVGContainer: {
          width: "50%",
          position: "relative"
        }
      });
    } else {
      this.setState({
        styleSVGContainer: {
          width: "50%",
          height: "0",
          paddingTop: "48%",
          position: "relative"
        },
        styleSVGMap: {
          position: "absolute"
        }
      });
    }
  }

  detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf("rv:");
      return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }

    var edge = ua.indexOf("Edge/");
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
    }

    // other browser
    return false;
  }

  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  allItems() {
    this.setState({
      items: this.state.itemsAll,
      showAllButton: false
    });
  }

  atvItems() {
    this.setState({
      items: this.state.itemsAtv,
      showAllButton: true
    });
  }

  tvlItems() {
    this.setState({
      items: this.state.itemsTvl,
      showAllButton: true
    });
  }

  tvoostItems() {
    this.setState({
      items: this.state.itemsTvoost,
      showAllButton: true
    });
  }

  robtvItems() {
    this.setState({
      items: this.state.itemsRobtv,
      showAllButton: true
    });
  }

  ringtvItems() {
    this.setState({
      items: this.state.itemsRingtv,
      showAllButton: true
    });
  }

  avsItems() {
    this.setState({
      items: this.state.itemsAVS,
      showAllButton: true
    });
  }

  wtvItems() {
    this.setState({
      items: this.state.itemsFocusWTV,
      showAllButton: true
    });
  }

  rtvItems() {
    this.setState({
      items: this.state.itemsRTV,
      showAllButton: true
    });
  }

  bruzzItems() {
    this.setState({
      items: this.state.itemsBruzz,
      showAllButton: true
    });
  }

  onClickAlleItems() {
    this.allItems();
    this.setState({
      buttonProps: this.state.buttonProps + 1
    });
  }

  render() {
    return (
      <div>
        <div className="mapContainer" style={this.state.style}>
          <div className="container">
            <div className="row">
              <div className="col-12 headline">
                Nieuws uit Vlaanderen en Brussel
              </div>
              <div
                className="col-12 no_padding"
                style={this.state.styleSVGContainer}
              >
                <Map
                  styleSVGMap={this.state.styleSVGMap}
                  allItems={this.allItems}
                  atvItems={this.atvItems}
                  tvlItems={this.tvlItems}
                  tvoostItems={this.tvoostItems}
                  robtvItems={this.robtvItems}
                  ringtvItems={this.ringtvItems}
                  avsItems={this.avsItems}
                  wtvItems={this.wtvItems}
                  rtvItems={this.rtvItems}
                  bruzzItems={this.bruzzItems}
                  buttonProps={this.state.buttonProps}
                />
              </div>
              {this.state.showAllButton ? (
                <div
                  className="alleItemsButton"
                  onClick={this.onClickAlleItems}
                >
                  Toon alle berichten
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="container">
          {this.state.loading ? <Loading /> : null}
          <Teasers items={this.state.items} />
          <Items items={this.state.items} />
        </div>
      </div>
    );
  }
}

export default App;
