import React, { Component } from 'react';
import './Teaser.css';

class Teasers extends Component {
  render() {
    return (
			<div className="row teasers">
      {this.props.items.slice(0, 3).map(Object =>
        <Teaser key={Object.id} item={Object}/>
      )}
      </div>
    );
  }
}

class Teaser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zenderColor: '',
    };
  }
  
  componentWillMount() {
    if (this.props.item.zender === "ATV") {
      this.setState({ zenderColor: "#496ea6" });
    }
    if (this.props.item.zender === "TV Limburg") {
      this.setState({ zenderColor: "#43b379" });
    }
    if (this.props.item.zender === "TV Oost") {
      this.setState({ zenderColor: "#dd3a52" });
    }
    if (this.props.item.zender === "ROBtv") {
      this.setState({ zenderColor: "#8a349a" });
    }
    if (this.props.item.zender === "RingTV") {
      this.setState({ zenderColor: "#ef2825" });
    }
    if (this.props.item.zender === "AVS") {
      this.setState({ zenderColor: "#0081F9" });
    }
    if (this.props.item.zender === "FocusWTV") {
      this.setState({ zenderColor: "#0ea3e9" });
    }
  }

	render() {
    const zenderStyle = {
      color: this.state.zenderColor,
    };
		return (
			<div className="col-md-4 teaser">
        <a href={this.props.item.url} target="_blank" rel="noopener noreferrer" className="teaser__link-wrap">
          <div className="teaser__img-wrap">
            <div className="aspect-ratio-placeholder teaser__img image responsive-image">
              <div className="aspect-ratio-placeholder__fill"></div>
              <img className="image responsive-image" alt="" src={this.props.item.thumbnail}>
              </img>
            </div>
          </div>
          <div className="teaser__info-wrap">
            <div className="teaser__vertical" style={zenderStyle}>
              <span>{this.props.item.zender}</span>
            </div>
            <div className="teaser__title">
              <span>{this.props.item.titel}</span>
            </div>
            <p className="teaser__excerpt ddd-truncated">
            {this.props.item.omschrijving}
            </p>
          </div>
        </a>
      </div>
		);
	}
}

export default Teasers;