import React, { Component } from 'react';

var divStyle = {
  stopColor: '#ADCFC2',
};

var divStyle2 = {
  stopColor: '#C4E4D9',
};

var divStyle3 = {
  stopColor: '#D7E9E6',
};

var imgStyle = {
  overflow:'visible',
};

class tvl extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
	
		<radialGradient id="SVGID_7_" cx="748.9068" cy="202.1136" r="30.3637" gradientTransform="matrix(0.6472 0.7623 -0.6857 0.5822 402.797 -486.4539)" gradientUnits="userSpaceOnUse">
		<stop  offset="0" style={divStyle}/>
		<stop  offset="0.4543" style={divStyle2}/>
		<stop  offset="1" style={divStyle3}/>
	</radialGradient>
	<path className="st11" d="M772.1,222c-0.3-1.4-0.2-3.2,1.5-5c4.4-4.4,7-10.1,7-16.3c0-14.1-13.7-25.5-30.5-25.5
		c-16.8,0-30.5,11.4-30.5,25.5c0,11.5,9,21.2,21.5,24.4c0,0,7.4,2.3,17.3,0.2c1.1-0.3,2.2-0.6,3.2-0.9c2.6-0.7,4.4,0.1,5.6,1
		l0.2,0.1c0,0,0,0,0,0l0,0l1.7,1.4l4.9,3.9l-1.3-6.1L772.1,222z"/>
	<g>
		
			<image style={imgStyle} width="1030" height="488" xlinkHref={require('../../assets/47FC4D33FDD04D3F.png')} transform="matrix(4.116213e-02 0 0 4.116213e-02 728.5867 198.0466)">
		</image>
	</g>
	<path className="st12" d="M722,192.8c10.9-15.4,36.4-21.6,53.9-3.7l-0.7,4.2c0,0-8.5-9-24.5-9c-16.7,0.1-20.4,5.5-26,10.7L722,192.8z"
		/>
	<g>
		<g>
			<g>
				<path className="st13" d="M753.8,195.9c-0.3,0.2-0.6,0.6-0.8,1.1l-2.7,8l-2.7-8c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.2-0.8-0.3-1.4-0.3
					h-2.6h-0.9h-2.4v-3.6h-3.8v3.6h-1.8v3.2h1.7v5.5c0,0.9,0.1,1.7,0.4,2.3c0.3,0.7,0.6,1.2,1.1,1.6c0.4,0.4,0.9,0.7,1.5,0.9
					c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,1.2-0.1,1.2-0.1l0.1,0v-3.5l-0.9,0.1c-0.6,0-1.1-0.2-1.6-0.5
					c-0.5-0.3-0.7-0.9-0.7-1.7v-5h2.4v0l0.3,0h0.6v0l1,0c0.2,0,0.4,0.1,0.4,0.3l3.9,10.4h2.7c0.6,0,1.1-0.1,1.4-0.3
					c0.3-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.2c0.1-0.2,0.2-0.3,0.4-0.3h1.6v-3.4h-3C754.7,195.5,754.2,195.7,753.8,195.9z"/>
				<path className="st13" d="M763,206.5c-0.3,0-0.4-0.1-0.4-0.4v-12.3c0-1.2-0.6-1.8-1.8-1.8h-3.1v2.9h1.1c0.3,0,0.4,0.1,0.4,0.4v12.3
					c0,0.6,0.1,1.1,0.4,1.4c0.3,0.3,0.7,0.4,1.4,0.4h3.1v-2.9H763z"/>
			</g>
		</g>
		<defs>
			<filter id="Adobe_OpacityMaskFilter_1_" filterUnits="userSpaceOnUse" x="733.9" y="191.9" width="30.2" height="17.6">
				
					<feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" colorInterpolationFilters="sRGB" result="source"/>
			</filter>
		</defs>
		<mask maskUnits="userSpaceOnUse" x="733.9" y="191.9" width="30.2" height="17.6" id="SVGID_8_">
			<g className="st14">
				
					<image style={imgStyle} width="35" height="22" xlinkHref={require('../../assets/47FC4D33FDD04D3C.jpg')}  transform="matrix(1 0 0 1 731.3002 189.7783)">
				</image>
			</g>
		</mask>
		<g className="st15">
			<g>
				<path className="st16" d="M753.8,195.9c-0.3,0.2-0.6,0.6-0.8,1.1l-2.7,8l-2.7-8c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.2-0.8-0.3-1.4-0.3
					h-2.6h-0.9h-2.4v-3.6h-3.8v3.6h-1.8v3.2h1.7v5.5c0,0.9,0.1,1.7,0.4,2.3c0.3,0.7,0.6,1.2,1.1,1.6c0.4,0.4,0.9,0.7,1.5,0.9
					c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.3,0.1c0.4,0,1.2-0.1,1.2-0.1l0.1,0v-3.5l-0.9,0.1c-0.6,0-1.1-0.2-1.6-0.5
					c-0.5-0.3-0.7-0.9-0.7-1.7v-5h2.4v0l0.3,0h0.6v0l1,0c0.2,0,0.4,0.1,0.4,0.3l3.9,10.4h2.7c0.6,0,1.1-0.1,1.4-0.3
					c0.3-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.2c0.1-0.2,0.2-0.3,0.4-0.3h1.6v-3.4h-3C754.7,195.5,754.2,195.7,753.8,195.9z"/>
				<path className="st16" d="M763,206.5c-0.3,0-0.4-0.1-0.4-0.4v-12.3c0-1.2-0.6-1.8-1.8-1.8h-3.1v2.9h1.1c0.3,0,0.4,0.1,0.4,0.4v12.3
					c0,0.6,0.1,1.1,0.4,1.4c0.3,0.3,0.7,0.4,1.4,0.4h3.1v-2.9H763z"/>
			</g>
		</g>
	</g>
</g>
    );
  }
}

export default tvl;