import React, { Component } from 'react';

class ringtv extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
      <path className="st25" d="M498.9,243.3h-57.2c-4.2,0-7.6-3.4-7.6-7.6v-25.9c0-4.2,3.4-7.6,7.6-7.6h57.2c4.2,0,7.6,3.4,7.6,7.6v25.9
      C506.5,239.9,503.1,243.3,498.9,243.3z"/>
    <g>
      <g>
        <g>
          <path className="st29" d="M461.2,232c0.1,0,0.3,0,0.3-0.2v-1c0,0,0-0.1-0.1-0.1h-0.1v-0.4h0.5c0.2,0,0.2,0.1,0.2,0.2v1.3
            c0,0.5-0.5,0.6-0.7,0.6c-0.1,0-0.1,0-0.1,0L461.2,232C461.2,232,461.2,232,461.2,232z M461.5,229.8h0.4v0.4h-0.4V229.8z"/>
          <path className="st29" d="M463.2,230.3c0.5,0,0.9,0.3,0.9,0.8c0,0.5-0.4,0.8-0.9,0.8s-0.9-0.3-0.9-0.8
            C462.3,230.6,462.7,230.3,463.2,230.3z M463.2,231.5c0.2,0,0.4-0.1,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.1-0.4,0.4
            C462.8,231.3,463,231.5,463.2,231.5z"/>
          <path className="st29" d="M464.3,230.8C464.3,230.8,464.3,230.8,464.3,230.8l-0.2-0.1v-0.4h0.5c0.2,0,0.2,0.1,0.2,0.2v0.7
            c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.2,0.3-0.4v-0.7h0.5v1c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.1,0-0.2-0.1-0.2-0.2v0c0,0,0,0,0,0
            h0c-0.1,0.2-0.3,0.3-0.5,0.3c-0.3,0-0.5-0.1-0.5-0.6L464.3,230.8L464.3,230.8z"/>
          <path className="st29" d="M466.3,230.8C466.2,230.8,466.2,230.8,466.3,230.8l-0.1,0v-0.4h0.4c0.1,0,0.2,0,0.2,0.1l0.2,0.8
            c0,0.1,0,0.2,0,0.2h0c0,0,0-0.1,0-0.2l0.2-0.9h0.4l0.2,0.9c0,0.1,0,0.2,0,0.2h0c0,0,0-0.1,0-0.2l0.2-0.8c0-0.1,0.1-0.1,0.2-0.1
            h0.4v0.4h0c0,0-0.1,0-0.1,0l-0.3,1h-0.6l-0.2-0.7c0-0.1,0-0.2,0-0.2h0c0,0,0,0.1,0,0.2l-0.2,0.7h-0.6L466.3,230.8z"/>
          <path className="st29" d="M469.6,230.8C469.6,230.8,469.6,230.8,469.6,230.8l-0.2-0.1v-0.4h0.5c0.1,0,0.2,0.1,0.2,0.2v0c0,0,0,0,0,0
            h0c0.1-0.1,0.2-0.3,0.5-0.3c0.3,0,0.6,0.1,0.6,0.6v0.5c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.2,0-0.2-0.1-0.2-0.2V231
            c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0,0.1,0,0.2v0.7h-0.5L469.6,230.8L469.6,230.8L469.6,230.8z"/>
          <path className="st29" d="M471.7,230.8C471.7,230.8,471.6,230.8,471.7,230.8l-0.2-0.1v-0.4h0.5c0.2,0,0.2,0.1,0.2,0.2v0.8
            c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.2,0-0.2-0.1-0.2-0.2V230.8L471.7,230.8z M471.7,229.8h0.4v0.4h-0.4V229.8L471.7,229.8z"/>
          <path className="st29" d="M473.3,230.3c0.5,0,0.7,0.3,0.7,0.7c0,0.1,0,0.2,0,0.2h-1c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.2,0.4-0.2
            l0.2,0.3c0,0-0.2,0.2-0.7,0.2c-0.6,0-0.8-0.4-0.8-0.8C472.5,230.7,472.8,230.3,473.3,230.3z M473.5,230.9c0-0.1-0.1-0.2-0.2-0.2
            c-0.1,0-0.2,0.1-0.3,0.2H473.5z"/>
          <path className="st29" d="M474.3,230.8C474.3,230.8,474.3,230.8,474.3,230.8l-0.2-0.1v-0.4h0.5c0.2,0,0.2,0.1,0.2,0.2v0.7
            c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.2,0.3-0.4v-0.7h0.5v1c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.1,0-0.2-0.1-0.2-0.2v0c0,0,0,0,0,0
            h0c-0.1,0.2-0.3,0.3-0.5,0.3c-0.3,0-0.5-0.1-0.5-0.6L474.3,230.8L474.3,230.8z"/>
          <path className="st29" d="M476.2,230.8C476.2,230.8,476.2,230.8,476.2,230.8l-0.1,0v-0.4h0.4c0.1,0,0.2,0,0.2,0.1l0.2,0.8
            c0,0.1,0,0.2,0,0.2h0c0,0,0-0.1,0-0.2l0.2-0.9h0.4l0.2,0.9c0,0.1,0,0.2,0,0.2h0c0,0,0-0.1,0-0.2l0.2-0.8c0-0.1,0.1-0.1,0.2-0.1
            h0.4v0.4h0c0,0-0.1,0-0.1,0l-0.3,1h-0.6l-0.2-0.7c0-0.1,0-0.2,0-0.2h0c0,0,0,0.1,0,0.2l-0.2,0.7h-0.6L476.2,230.8z"/>
          <path className="st29" d="M478.9,231.3c0,0,0.2,0.2,0.5,0.2c0.1,0,0.1,0,0.1-0.1c0-0.1-0.8-0.2-0.8-0.7c0-0.3,0.3-0.5,0.6-0.5
            c0.3,0,0.6,0.1,0.6,0.3v0.2h-0.4v-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1,0.1c0,0.2,0.8,0.1,0.8,0.6c0,0.3-0.3,0.5-0.6,0.5
            c-0.4,0-0.7-0.3-0.7-0.3L478.9,231.3z"/>
          <path className="st29" d="M480.9,231.4l-0.3,0.8h-0.4l0.2-0.8H480.9z"/>
          <path className="st29" d="M481.8,232c0.1,0,0.3,0,0.3-0.2v-1c0,0,0-0.1-0.1-0.1h-0.1v-0.4h0.5c0.2,0,0.2,0.1,0.2,0.2v1.3
            c0,0.5-0.5,0.6-0.7,0.6c-0.1,0-0.1,0-0.1,0L481.8,232C481.7,232,481.7,232,481.8,232z M482.1,229.8h0.4v0.4h-0.4V229.8z"/>
          <path className="st29" d="M483.7,230.3c0.5,0,0.9,0.3,0.9,0.8c0,0.5-0.4,0.8-0.9,0.8s-0.9-0.3-0.9-0.8
            C482.9,230.6,483.2,230.3,483.7,230.3z M483.7,231.5c0.2,0,0.4-0.1,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.1-0.4,0.4
            C483.4,231.3,483.5,231.5,483.7,231.5z"/>
          <path className="st29" d="M484.9,230.8C484.9,230.8,484.9,230.8,484.9,230.8l-0.2-0.1v-0.4h0.5c0.2,0,0.2,0.1,0.2,0.2v0.7
            c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.3-0.2,0.3-0.4v-0.7h0.5v1c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.1,0-0.2-0.1-0.2-0.2v0c0,0,0,0,0,0
            h0c-0.1,0.2-0.3,0.3-0.5,0.3c-0.3,0-0.5-0.1-0.5-0.6L484.9,230.8L484.9,230.8z"/>
          <path className="st29" d="M486.8,230.8C486.8,230.8,486.8,230.8,486.8,230.8l-0.1,0v-0.4h0.4c0.1,0,0.2,0,0.2,0.1l0.2,0.8
            c0,0.1,0,0.2,0,0.2h0c0,0,0-0.1,0-0.2l0.2-0.9h0.4l0.2,0.9c0,0.1,0,0.2,0,0.2h0c0,0,0-0.1,0-0.2l0.2-0.8c0-0.1,0.1-0.1,0.2-0.1
            h0.4v0.4h0c0,0-0.1,0-0.1,0l-0.3,1h-0.6l-0.2-0.7c0-0.1,0-0.2,0-0.2h0c0,0,0,0.1,0,0.2l-0.2,0.7h-0.6L486.8,230.8z"/>
          <path className="st29" d="M490,231.6l0.6-0.7c0.1-0.1,0.1-0.1,0.1-0.1v0c0,0,0,0-0.1,0h-0.1c0,0-0.1,0-0.1,0.1v0.1H490v-0.3
            c0-0.2,0.1-0.2,0.2-0.2h1.2v0.3l-0.6,0.7c-0.1,0.1-0.1,0.1-0.1,0.1v0c0,0,0,0,0.1,0h0.1c0,0,0.1,0,0.1-0.1v-0.1h0.5v0.3
            c0,0.2-0.1,0.2-0.2,0.2H490L490,231.6L490,231.6z"/>
          <path className="st29" d="M492.4,230.3c0.5,0,0.7,0.3,0.7,0.7c0,0.1,0,0.2,0,0.2h-1c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.2,0.4-0.2
            l0.2,0.3c0,0-0.2,0.2-0.7,0.2c-0.6,0-0.8-0.4-0.8-0.8C491.6,230.7,491.9,230.3,492.4,230.3z M492.5,230.9c0-0.1-0.1-0.2-0.2-0.2
            c-0.1,0-0.2,0.1-0.3,0.2H492.5z"/>
          <path className="st29" d="M493.4,230.8C493.4,230.8,493.4,230.8,493.4,230.8l-0.2-0.1v-0.4h0.5c0.1,0,0.2,0.1,0.2,0.2v0c0,0,0,0,0,0
            h0c0.1-0.1,0.2-0.3,0.5-0.3c0.3,0,0.6,0.1,0.6,0.6v0.5c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.2,0-0.2-0.1-0.2-0.2V231
            c0-0.1-0.1-0.2-0.2-0.2c-0.2,0-0.3,0.1-0.3,0.2c0,0.1,0,0.1,0,0.2v0.7h-0.5L493.4,230.8L493.4,230.8L493.4,230.8z"/>
          <path className="st29" d="M496,230.3c0.3,0,0.4,0.1,0.4,0.1h0c0,0,0,0,0-0.1v-0.1c0,0,0-0.1-0.1-0.1h-0.1v-0.4h0.5
            c0.2,0,0.2,0.1,0.2,0.2v1.4c0,0,0,0.1,0.1,0.1h0.1v0.4h-0.5c-0.1,0-0.2-0.1-0.2-0.1c0,0,0,0,0,0h0c0,0-0.1,0.2-0.4,0.2
            c-0.4,0-0.7-0.3-0.7-0.8C495.3,230.6,495.6,230.3,496,230.3z M496.4,231.1c0-0.2-0.1-0.4-0.3-0.4c-0.2,0-0.3,0.1-0.3,0.4
            c0,0.2,0.1,0.4,0.3,0.4C496.2,231.5,496.4,231.4,496.4,231.1z"/>
          <path className="st29" d="M498,230.3c0.5,0,0.7,0.3,0.7,0.7c0,0.1,0,0.2,0,0.2h-1c0,0.2,0.2,0.3,0.4,0.3c0.2,0,0.4-0.2,0.4-0.2
            l0.2,0.3c0,0-0.2,0.2-0.7,0.2c-0.6,0-0.8-0.4-0.8-0.8C497.2,230.7,497.5,230.3,498,230.3z M498.2,230.9c0-0.1-0.1-0.2-0.2-0.2
            c-0.1,0-0.2,0.1-0.3,0.2H498.2z"/>
          <path className="st29" d="M499.1,230.8C499.1,230.8,499,230.8,499.1,230.8l-0.2-0.1v-0.4h0.5c0.1,0,0.2,0.1,0.2,0.2v0.1
            c0,0,0,0.1,0,0.1h0c0.1-0.2,0.3-0.4,0.5-0.4c0,0,0.1,0,0.1,0v0.5c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0.3c0,0.1,0,0.1,0,0.2v0.6
            h-0.5L499.1,230.8L499.1,230.8L499.1,230.8z"/>
        </g>
      </g>
      <g>
        <path className="st29" d="M488.6,224.3h-0.9v-1.5h0.9v-1.5h2.2v1.5h1.4v1.5h-1.4v1.7c0,0.6,0.8,0.7,1.2,0.7c0.2,0,0.3,0,0.3,0v1.6
          c0,0-0.3,0-0.6,0c-1.1,0-3.2-0.2-3.2-2.1L488.6,224.3L488.6,224.3z"/>
        <path className="st29" d="M493.3,224.5c-0.1-0.1-0.1-0.2-0.3-0.2h-0.2v-1.5h1.5c0.5,0,0.8,0.2,0.9,0.4l1,2.5c0.1,0.4,0.3,0.9,0.3,0.9
          h0c0,0,0.1-0.5,0.3-0.9l1-2.5c0.1-0.3,0.5-0.4,0.9-0.4h1.3v1.5h-0.2c-0.2,0-0.3,0.1-0.3,0.2l-1.7,3.8H495L493.3,224.5z"/>
      </g>
      <g>
        <path className="st30" d="M471.9,218.3c-0.9,0-1.3,0.3-1.3,1v3.3c0,0.6,0.1,1.5,0.1,1.5h0c0,0-0.5-0.9-1-1.5l-3.5-4.3h-2.8v7.6
          c0,0.2-0.1,0.3-0.4,0.3h-0.9c-0.2,0.7-0.4,1.4-0.8,2.1h3.9c0.9,0,1.3-0.3,1.3-1V224c0-0.6-0.1-1.5-0.1-1.5h0c0,0,0.5,0.9,1,1.5
          l3.5,4.3h2.8v-7.6c0-0.2,0.1-0.3,0.4-0.3h0.8v-2.1L471.9,218.3L471.9,218.3L471.9,218.3z"/>
      </g>
      <path className="st30" d="M480.6,218.3c3.1,0,4.6,1.3,4.6,1.3l-1.7,1.7c0,0-1.2-0.8-2.8-0.8c-1.6,0-3.2,1-3.2,2.7
        c0,1.9,1.6,2.9,3.4,2.9c1.6,0,2.5-0.7,2.5-0.7v-0.4c0-0.2-0.1-0.3-0.4-0.3h-0.7v-2.1h2.6c0.9,0,1.3,0.3,1.3,1v4.6h-2.6V228
        c0-0.2,0-0.4,0-0.4h0c0,0-1.2,0.9-3.3,0.9c-3.1,0-6-1.8-6-5.2C474.3,220.4,477,218.3,480.6,218.3z"/>
      <g>
        <g>
          <path className="st30" d="M456.5,228.3L456.5,228.3h-1.6c-0.9,0-1.4-0.1-1.8-0.7l-1.5-2.3c-0.2-0.3-0.5-0.4-1.1-0.4h-0.4v3.4H447
            v-7.9h-1.1v-2.1h5.8c2.4,0,4.1,1.1,4.1,3.1c0,2-1.8,2.6-1.8,2.6v0c0,0,0.5,0.2,0.8,0.6l0.8,1.2c0.2,0.3,0.5,0.3,0.9,0.3h0.2l0,0
            h1.6v-5.8h-1.3v-2.1h4.4c-1.9-4-5.9-6.7-10.6-6.7c-6.5,0-11.7,5.3-11.7,11.7s5.3,11.7,11.7,11.7c4.7,0,8.7-2.7,10.6-6.7H456.5
            L456.5,228.3z"/>
          <path className="st30" d="M461.2,226.2h0.9c0.2-0.9,0.4-1.9,0.4-2.9c0-1-0.1-2-0.4-2.9h-0.9L461.2,226.2L461.2,226.2L461.2,226.2z"
            />
          <path className="st30" d="M450.1,220.4v2.4h1.1c0.9,0,1.4-0.4,1.4-1.2c0-0.8-0.5-1.2-1.4-1.2H450.1L450.1,220.4z"/>
        </g>
      </g>
    </g>  
</g>

    );
  }
}

export default ringtv;