import React, { Component } from 'react';

var divStyle = {
  stopColor: '#824D99',
};

var divStyle2 = {
  stopColor: '#5D2378',
};

class robtv extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
	<linearGradient id="SVGID_18_" gradientUnits="userSpaceOnUse" x1="599.3798" y1="267.8791" x2="599.144" y2="231.068">
		<stop  offset="0" style={divStyle}/>
		<stop  offset="1" style={divStyle2}/>
	</linearGradient>
	<path className="st28" d="M627.1,270.7h-55.7c-2.9,0-5.3-2.4-5.3-5.3v-33.5c0-2.9,2.4-5.3,5.3-5.3h55.7c2.9,0,5.3,2.4,5.3,5.3v33.5
		C632.4,268.3,630,270.7,627.1,270.7z"/>
	<g>
		<polygon className="st25" points="617.8,236.4 618.1,238.1 616.2,238.4 617,243 614.9,243.4 614.1,238.8 611.5,239.3 611.2,237.6 		
			"/>
		<polygon className="st25" points="619.4,236.1 621.1,239.9 621.9,233.3 623.8,233.8 622.6,242 620.5,242.4 617.2,236.5 		"/>
		<path className="st25" d="M619.9,247.4c-4.5,0.8-8.9-2.2-9.7-6.8c-0.8-4.5,2.2-8.9,6.8-9.7c4.5-0.8,8.9,2.2,9.7,6.8
			C627.4,242.3,624.4,246.6,619.9,247.4 M617.3,233c-3.4,0.6-5.7,3.9-5.1,7.3c0.6,3.4,3.9,5.7,7.3,5.1c3.4-0.6,5.7-3.9,5.1-7.3
			C624,234.7,620.7,232.4,617.3,233"/>
		<path className="st25" d="M582.7,263.7l-4.8-4.7l-1.2,0.2l0.9,5.4l-4,0.7l-2.9-16.5l5.2-0.9c0.4-0.1,0.9-0.1,1.3-0.2
			c0.4-0.1,0.9-0.1,1.4-0.1c0.5,0,0.9,0,1.3,0.1c0.4,0.1,0.9,0.2,1.3,0.5c0.8,0.4,1.4,1,1.9,1.8c0.5,0.7,0.8,1.5,1,2.4
			c0.1,0.6,0.1,1.3,0.1,1.9c-0.1,0.6-0.3,1.2-0.5,1.7c-0.3,0.5-0.6,1-1.1,1.4c-0.2,0.2-0.4,0.4-0.6,0.5l5.6,5.1L582.7,263.7z
			 M579.9,253.3c-0.1-0.8-0.5-1.4-1.1-1.6c-0.6-0.2-1.3-0.3-2-0.2l-1.5,0.3l0.8,4.6l1.5-0.3c0.8-0.1,1.4-0.4,1.9-0.9
			C579.9,254.8,580.1,254.1,579.9,253.3"/>
		<path className="st25" d="M600.8,252c0.2,1.2,0.2,2.4-0.1,3.5c-0.3,1.1-0.7,2.2-1.4,3.1c-0.7,0.9-1.5,1.7-2.5,2.3
			c-1,0.6-2.1,1-3.3,1.2c-1.2,0.2-2.4,0.2-3.5-0.1c-1.1-0.3-2.2-0.7-3.1-1.3c-0.9-0.6-1.7-1.4-2.3-2.4c-0.6-1-1.1-2.1-1.3-3.3
			c-0.2-1.2-0.2-2.4,0.1-3.5c0.3-1.1,0.7-2.2,1.4-3.1c0.6-0.9,1.5-1.7,2.4-2.3c1-0.6,2.1-1,3.3-1.3c1.2-0.2,2.4-0.2,3.5,0.1
			c1.1,0.3,2.2,0.7,3.1,1.3c0.9,0.6,1.7,1.4,2.4,2.4C600.2,249.7,600.6,250.8,600.8,252 M597,252.7c-0.1-0.6-0.3-1.2-0.7-1.8
			c-0.3-0.6-0.8-1-1.3-1.4c-0.5-0.4-1.1-0.6-1.7-0.8c-0.6-0.2-1.3-0.2-2.1,0c-0.7,0.1-1.4,0.4-1.9,0.7c-0.6,0.4-1,0.8-1.4,1.3
			c-0.3,0.5-0.6,1.1-0.7,1.7c-0.1,0.6-0.1,1.3,0,1.9c0.1,0.7,0.3,1.3,0.7,1.8c0.3,0.6,0.8,1,1.3,1.4c0.5,0.4,1.1,0.6,1.7,0.8
			c0.7,0.2,1.4,0.2,2.1,0c0.7-0.1,1.3-0.4,1.9-0.7c0.5-0.4,1-0.8,1.3-1.3c0.3-0.5,0.6-1.1,0.7-1.7C597.1,254,597.1,253.4,597,252.7"
			/>
		<path className="st25" d="M613.2,253.2c0.1,0.7,0.1,1.4,0,2c-0.2,0.6-0.4,1.3-0.8,1.8c-0.3,0.4-0.7,0.8-1.1,1.1
			c-0.4,0.3-0.8,0.5-1.2,0.7c-0.4,0.2-0.9,0.3-1.4,0.4c-0.5,0.1-1,0.2-1.5,0.3l-5.2,0.9l-2.9-16.5l5.1-0.9c0.4-0.1,0.8-0.1,1.2-0.2
			c0.4-0.1,0.9-0.1,1.3-0.1c0.4,0,0.9,0,1.3,0c0.4,0.1,0.8,0.2,1.1,0.4c0.6,0.3,1.1,0.8,1.4,1.3c0.4,0.6,0.6,1.2,0.7,1.8
			c0.1,0.7,0.1,1.4-0.1,2c-0.2,0.6-0.6,1.2-1.2,1.7c1,0.1,1.7,0.5,2.3,1.1C612.6,251.5,613,252.2,613.2,253.2 M607.2,247.5
			c0-0.3-0.1-0.5-0.3-0.6c-0.1-0.1-0.3-0.3-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.7,0.1l-1.5,0.3l0.5,2.6l1.5-0.3
			c0.5-0.1,0.9-0.2,1.2-0.5C607.2,248.4,607.3,248,607.2,247.5 M609.1,253.6c-0.1-0.3-0.2-0.6-0.3-0.7c-0.2-0.2-0.3-0.3-0.6-0.3
			c-0.2-0.1-0.5-0.1-0.7-0.1c-0.3,0-0.5,0-0.8,0.1l-2,0.3l0.5,3l2-0.3c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.2,0.6-0.4
			c0.2-0.2,0.3-0.3,0.4-0.6C609.1,254.2,609.1,253.9,609.1,253.6"/>
	</g>
</g>
    );
  }
}

export default robtv;