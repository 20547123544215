import React, { Component } from 'react';
import { SVGMap, Belgium } from './map/index';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
			selectedLocation: null
		};
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.isLocationSelected = this.isLocationSelected.bind(this);
    this.onLogoClickAntwerpen = this.onLogoClickAntwerpen.bind(this);
    this.onLogoClickLimburg = this.onLogoClickLimburg.bind(this);
    this.onLogoClickOostVlaanderen = this.onLogoClickOostVlaanderen.bind(this);
    this.onLogoClickWestVlaanderen = this.onLogoClickWestVlaanderen.bind(this);
    this.onLogoClickVlaamsBrabant = this.onLogoClickVlaamsBrabant.bind(this);
    this.onLogoClickBrussel = this.onLogoClickBrussel.bind(this);
    this.onLogoClickATV = this.onLogoClickATV.bind(this);
    this.onLogoClickROB = this.onLogoClickROB.bind(this);
    this.onLogoClickTVO = this.onLogoClickTVO.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.buttonProps!==this.props.buttonProps){
      this.setState({selectedLocation: null});
    }
  }

  handleLocationClick(event) {
    if (event.target.attributes.name.value === this.state.selectedLocation) {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: event.target.attributes.name.value });
      if (event.target.attributes.name.value === "Antwerpen") {
        this.props.rtvItems();
      }
      if (event.target.attributes.name.value === "Limburg") {
        this.props.tvlItems();
      }
      if (event.target.attributes.name.value === "Oost-Vlaanderen") {
        this.props.avsItems();
      }
      if (event.target.attributes.name.value === "West-Vlaanderen") {
        this.props.wtvItems();
      }
      if (event.target.attributes.name.value === "Vlaams-Brabant") {
        this.props.ringtvItems();
      }
      if (event.target.attributes.name.value === "Bruzz") {
        this.props.bruzzItems();
      }
      if (event.target.attributes.name.value === "ATV") {
        this.props.atvItems();
      }
      if (event.target.attributes.name.value === "ROB") {
        this.props.robtvItems();
      }
      if (event.target.attributes.name.value === "TVO") {
        this.props.tvoostItems();
      }
    }
  }

  isLocationSelected(location) {
		return this.state.selectedLocation === location.name;
  }
  
  onLogoClickAntwerpen(){
    if (this.state.selectedLocation === "Antwerpen") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "Antwerpen" });
      this.props.rtvItems();
    }
  }

  onLogoClickLimburg(){
    if (this.state.selectedLocation === "Limburg") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "Limburg" });
      this.props.tvlItems();
    }
  }

  onLogoClickOostVlaanderen(){
    if (this.state.selectedLocation === "Oost-Vlaanderen") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "Oost-Vlaanderen" });
      this.props.avsItems();
    }
  }

  onLogoClickWestVlaanderen(){
    if (this.state.selectedLocation === "West-Vlaanderen") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "West-Vlaanderen" });
      this.props.wtvItems();
    }
  }

  onLogoClickVlaamsBrabant(){
    if (this.state.selectedLocation === "Vlaams-Brabant") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "Vlaams-Brabant" });
      this.props.ringtvItems();
    }
  }

  onLogoClickBrussel(){
    if (this.state.selectedLocation === "Bruzz") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "Bruzz" });
      this.props.bruzzItems();
    }
  }

  onLogoClickATV(){
    if (this.state.selectedLocation === "ATV") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "ATV" });
      this.props.atvItems();
    }
  }

  onLogoClickROB(){
    if (this.state.selectedLocation === "ROB") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "ROB" });
      this.props.robtvItems();
    }
  }

  onLogoClickTVO(){
    if (this.state.selectedLocation === "TVO") {
      this.setState({ selectedLocation: null });
      this.props.allItems();
    } else {
      this.setState({ selectedLocation: "TVO" });
      this.props.tvoostItems();
    }
  }

  render() {
    return (
        <SVGMap
          map={Belgium}
          type="radio"
          onLocationMouseOver={this.handleLocationMouseOver}
          onLocationMouseOut={this.handleLocationMouseOut}
          onLocationClick={this.handleLocationClick}
          onLocationFocus={this.handleLocationFocus}
          onLocationBlur={this.handleLocationBlur}
          isLocationSelected={this.isLocationSelected}
          onLogoClickAntwerpen={this.onLogoClickAntwerpen}
          onLogoClickLimburg={this.onLogoClickLimburg}
          onLogoClickOostVlaanderen={this.onLogoClickOostVlaanderen}
          onLogoClickWestVlaanderen={this.onLogoClickWestVlaanderen}
          onLogoClickVlaamsBrabant={this.onLogoClickVlaamsBrabant}
          onLogoClickBrussel={this.onLogoClickBrussel}
          onLogoClickATV={this.onLogoClickATV}
          onLogoClickROB={this.onLogoClickROB}
          onLogoClickTVO={this.onLogoClickTVO}
          styleSVGMap={this.props.styleSVGMap}
        />
    );
  }
}

export default Map;