import React, { Component } from 'react';

var imgStyle = {
  overflow:'visible',
};

class avs extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
      <path className="st25" d="M325.5,183.6h-72.1c-4.2,0-7.6-3.4-7.6-7.6v-25.9c0-4.2,3.4-7.6,7.6-7.6h72.1c4.2,0,7.6,3.4,7.6,7.6v25.9
      C333,180.2,329.6,183.6,325.5,183.6z"/>
    
      <image style={imgStyle} width="2466" height="939" xlinkHref={require('../../assets/CB94088D02BA4E3F.jpg')}  transform="matrix(3.110369e-02 0 0 3.110369e-02 249.2234 149.5173)">
    </image>
</g>

    );
  }
}

export default avs;