import React, { Component } from 'react';

class rtv extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
      <rect x="577.1" y="127.5" className="st31" width="53.1" height="53.1"/>
      <g>
        <path className="st25" d="M598.4,132.7c0.2-0.1,0.5,0,0.7,0c6,1.4,12.1,2.8,18.1,4.2c0.4,0.1,0.7,0.5,0.7,0.9c0,3.8,0,7.7,0,11.5
          c0,0.4-0.3,0.8-0.8,0.8c-0.8,0.1-1.6,0.2-2.4,0.3c-0.4,0.1-0.7-0.3-0.7-0.7c0-3.3,0-6.5,0-9.8c0-0.4-0.3-0.9-0.8-0.9
          c-4.8-1-9.7-2-14.5-3.1c-0.4-0.1-0.8-0.5-0.8-1c0-0.6,0-1.2,0-1.8C598.2,133.1,598.3,132.8,598.4,132.7z"/>
        <path className="st25" d="M591.1,134.5c0.2-0.1,0.4-0.1,0.6,0c1.9,0.4,3.9,0.8,5.8,1.1c0.3,0.1,0.6,0.3,0.7,0.7c0.1,0.5,0,1,0.1,1.4
          c0.1,0.3,0.4,0.5,0.7,0.6c4.2,0.8,8.3,1.7,12.5,2.5c0.4,0.1,0.8,0.5,0.7,0.9c0,2.7-0.1,5.4-0.1,8.1c0,0.4-0.3,0.8-0.8,0.8
          c-4.2,0.3-8.3,0.5-12.5,0.8c-0.4,0-0.7-0.3-0.7-0.6c0-4,0.1-8,0.1-12c0-0.4-0.3-0.8-0.7-0.8c-0.9-0.2-1.7-0.3-2.6-0.5
          c-0.3-0.1-0.6,0.1-0.6,0.4c-0.1,0.2,0,0.4,0,0.6c0,5-0.1,10-0.1,15c0,0.4,0.3,0.7,0.7,0.7c5.5-0.5,11-0.9,16.5-1.4
          c0.2,0,0.5,0,0.6-0.2c0.3-0.5-0.1-1.2,0.3-1.6c0.3-0.3,0.7-0.2,1.1-0.3c0.4-0.1,0.8,0.3,0.8,0.7c0,1,0.1,2,0.1,3.1
          c0,0.4-0.2,0.7-0.6,0.8c-0.2,0.1-0.3,0.1-0.5,0.1c-7,0.7-14.1,1.5-21.1,2.3c-0.4,0.1-0.9-0.1-1.2-0.4c-0.3-0.3-0.4-0.7-0.4-1
          c0.1-7,0.2-14,0.3-21.1C590.8,134.9,590.9,134.6,591.1,134.5z"/>
        <path className="st25" d="M600.2,160.4c0-0.3,0.3-0.5,0.5-0.5c0.6,0,1.3,0,1.9,0c0.3,0,0.5,0.2,0.5,0.5c0,0.9,0,1.8,0,2.7
          c0.5,0,1,0,1.4,0c0.3,0,0.4,0.3,0.5,0.5c0.2,0.5,0.3,1,0.5,1.5c0.1,0.3-0.1,0.7-0.5,0.6c-0.7,0-1.3,0-2,0c0,2,0,4,0,6
          c0,0.4,0.1,0.8,0.5,0.9c0.6,0.3,1.3,0.1,1.9,0.1c0.1,0,0.3,0.1,0.3,0.3c0.2,0.6,0.5,1.2,0.7,1.9c0.1,0.3-0.3,0.5-0.5,0.5
          c-0.9,0-1.8,0-2.7,0c-0.9-0.1-1.9-0.2-2.6-0.9c-0.6-0.6-0.8-1.6-0.8-2.5c0-2.1,0-4.3,0-6.4c-0.5,0-0.9,0-1.4,0
          c-0.3,0-0.5-0.3-0.5-0.5c0-0.5,0-1,0-1.6c0-0.3,0.2-0.5,0.5-0.5c0.5,0,1,0,1.5,0C600.2,162.2,600.2,161.3,600.2,160.4z"/>
        <path className="st25" d="M590.4,163.7c0-0.3,0.2-0.5,0.5-0.5c0.8,0,1.5,0,2.3,0c0.2,0,0.5,0.1,0.6,0.3c0.1,0.2,0,0.5,0,0.8
          c0.1,0.1,0.1,0,0.1-0.1c0.2-0.3,0.6-0.5,0.9-0.6c0.7-0.3,1.4-0.3,2.1-0.4c0.2,0,0.5,0.1,0.5,0.4c0,0.6,0,1.1,0,1.7
          c0,0.3-0.2,0.5-0.5,0.5c-0.6,0-1.2-0.1-1.8,0.2c-0.8,0.3-1.4,1.2-1.4,2.1c0,2.3,0,4.6,0,6.9c0,0.3-0.3,0.5-0.6,0.5
          c-0.8,0-1.5,0-2.3,0c-0.3,0-0.5-0.2-0.5-0.5C590.4,171.2,590.4,167.4,590.4,163.7z"/>
        <path className="st25" d="M605.7,163.6c-0.1-0.2,0.1-0.5,0.3-0.5c0.8,0,1.6,0,2.4,0c0.3,0,0.7,0.2,0.8,0.5c0.6,1.6,1.2,3.2,1.8,4.8
          c0.4,1,0.7,2,1.1,2.9c0.9-2.6,1.8-5.1,2.7-7.7c0.1-0.3,0.5-0.5,0.8-0.5c0.7,0,1.4,0,2.1,0c0.2,0,0.4,0.3,0.3,0.5
          c-1.4,3.7-2.7,7.5-4.1,11.2c-0.1,0.3-0.4,0.6-0.8,0.6c-0.8,0-1.5,0-2.2,0c-0.3,0-0.8-0.1-0.9-0.5
          C608.7,171.1,607.2,167.4,605.7,163.6z"/>
      </g>
      </g>
    );
  }
}

export default rtv;