import React, { Component } from 'react';
import './Items.css';

class Items extends Component {
  render() {
    return (
			<div className="row">
      {this.props.items.slice(3).map(Object =>
        <Item key={Object.id} item={Object}/>
      )}
      </div>
    );
  }
}

class Item extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zenderColor: '',
    };
  }
  
  componentWillMount() {
    if (this.props.item.zender === "ATV") {
      this.setState({ zenderColor: "#496ea6" });
    }
    if (this.props.item.zender === "TV Limburg") {
      this.setState({ zenderColor: "#43b379" });
    }
    if (this.props.item.zender === "TV Oost") {
      this.setState({ zenderColor: "#dd3a52" });
    }
    if (this.props.item.zender === "ROBtv") {
      this.setState({ zenderColor: "#8a349a" });
    }
    if (this.props.item.zender === "RingTV") {
      this.setState({ zenderColor: "#ef2825" });
    }
    if (this.props.item.zender === "AVS") {
      this.setState({ zenderColor: "#0081F9" });
    }
    if (this.props.item.zender === "FocusWTV") {
      this.setState({ zenderColor: "#0ea3e9" });
    }
  }

	render() {
    const zenderStyle = {
      color: this.state.zenderColor,
    };
		return (
      <div className="col-md-6">
       <div className="item">
        <a href={this.props.item.url} target="_blank" rel="noopener noreferrer" className="item__link-wrap">
          <div className="item__img-wrap">
            <div className="aspect-ratio-placeholder item__img image responsive-image">
              <div className="aspect-ratio-placeholder__fill"></div>
              <img className="image responsive-image" alt="" src={this.props.item.thumbnail}>
              </img>
            </div>
          </div>
          <div className="item__info-wrap">
            <div className="item__vertical" style={zenderStyle}>
              <span>{this.props.item.zender}</span>
            </div>
            <div className="item__title">
              <span>{this.props.item.titel}</span>
            </div>
            <p className="item__excerpt ddd-truncated">
            {this.props.item.omschrijving}
            </p>
          </div>
        </a>
        </div>
      </div>
		);
	}
}

export default Items;