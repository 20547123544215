import React, { Component } from 'react';

var divStyle = {
  stopColor: '#E5D2CE',
};

var divStyle2 = {
  stopColor: '#DCBEB8',
};

var divStyle3 = {
  stopColor: '#F2E8E8',
};

var imgStyle = {
  overflow:'visible',
};

var imgStyle2 = {
  overflow:'visible',
  fill:'url(#SVGID_11_)'
};

class tvo extends Component {
  render() {
    return (
      <g onClick={this.props.onLogoClick} className="logo">
	
      <radialGradient id="SVGID_9_" cx="395.0901" cy="202.9129" r="35.1666" gradientTransform="matrix(0.64 0.7683 -0.6912 0.5758 282.4601 -217.4778)" gradientUnits="userSpaceOnUse">
      <stop  offset="0" style={divStyle}/>
      <stop  offset="0.5079" style={divStyle2}/>
      <stop  offset="1" style={divStyle3}/>
    </radialGradient>
    <path className="st17" d="M412.4,217.8c-0.3-1.4-0.2-3.2,1.5-5c4.4-4.4,7-10.1,7-16.2c0-14.1-13.6-25.5-30.4-25.5S360,182.5,360,196.6
      c0,11.5,9,21.1,21.4,24.3c0,0,7.4,2.3,17.3,0.2c1.1-0.3,2.2-0.6,3.2-0.9c2.6-0.7,4.4,0.1,5.6,1l0.2,0.1c0,0,0,0,0,0l0,0l1.7,1.4
      l4.9,3.9l-1.3-6.1L412.4,217.8z"/>
    <g className="st2">
      <defs>
        <path id="SVGID_10_" className="st2" d="M412.3,217.7c-0.3-1.4-0.2-3.2,1.5-5c4.4-4.4,7-10.1,7-16.2c0-14.1-13.6-25.5-30.4-25.5
          s-30.4,11.4-30.4,25.5c0,11.4,9,21.1,21.4,24.3c0,0,7.4,2.3,17.3,0.2c1.1-0.3,2.2-0.6,3.2-0.9c2.6-0.7,4.4,0.1,5.6,1l0.2,0.1
          c0,0,0,0,0,0l0,0l1.7,1.4l4.9,3.9l-1.3-6.1L412.3,217.7z"/>
      </defs>
      <radialGradient id="SVGID_11_" cx="414.8562" cy="225.7187" r="44.5216" gradientUnits="userSpaceOnUse">
        <stop  offset="0.1532" style={divStyle3}/>
        <stop  offset="0.3124" style={divStyle3}/>
        <stop  offset="0.5474" style={divStyle2}/>
        <stop  offset="1" style={divStyle3}/>
      </radialGradient>
      <use xlinkHref="#SVGID_10_"  style={imgStyle2}/>
      <clipPath id="SVGID_12_">
        <use xlinkHref="#SVGID_10_"  style={imgStyle}/>
      </clipPath>
      <g className="st18">
        
          <image style={imgStyle} width="1316" height="1371" xlinkHref={require('../../assets/35EF3E805A050905.png')} transform="matrix(4.106477e-02 0 0 4.106477e-02 375.7004 171.8212)">
        </image>
      </g>
    </g>
    <g>
      
        <image style={imgStyle} width="1067" height="488" xlinkHref={require('../../assets/35EF3E805A050907.png')}  transform="matrix(4.106477e-02 0 0 4.106477e-02 369.0005 193.8935)">
      </image>
    </g>
    <path className="st19" d="M362.4,188.6c10.8-15.4,36.3-21.6,53.7-3.7l-0.7,4.2c0,0-8.4-9-24.4-9c-16.7,0.1-20.3,5.5-25.9,10.7
      L362.4,188.6z"/>
    <g>
      <g>
        <g>
          <path className="st20" d="M396,191.1h-3.1c-0.6,0-1.1,0.1-1.4,0.3c-0.4,0.2-0.6,0.6-0.8,1.1l-2.8,8.1l-2.7-8.1
            c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.2-0.8-0.3-1.4-0.3h-2.6h-0.4h-2.4v-3.6h-3.9v3.6h-1.8v3.3h1.7v5.5c0,0.9,0.1,1.7,0.4,2.4
            c0.3,0.7,0.7,1.2,1.1,1.6c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.1-0.1l0.2,0
            v-3.5l-0.9,0.1c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.3-0.7-0.9-0.7-1.7v-5.1h2.4v0l0.3,0h0.2v0l1,0c0.2,0,0.4,0.1,0.5,0.3l3.9,10.5
            h2.7c0.6,0,1.1-0.1,1.4-0.3c0.4-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.3c0.1-0.2,0.2-0.3,0.5-0.3h1.6V191.1z"/>
          <path className="st20" d="M406.7,193c-1.3-1.3-2.9-2-4.7-2c-1.8,0-3.4,0.7-4.7,2c-1.3,1.3-1.9,3.1-1.9,5.1c0,2.1,0.6,3.8,1.9,5.1
            c1.3,1.3,2.8,2,4.7,2c1.9,0,3.4-0.7,4.7-2c1.3-1.3,1.9-3.1,1.9-5.1C408.6,196,408,194.3,406.7,193z M404.1,200.7
            c-0.6,0.7-1.3,1-2.1,1c-0.8,0-1.5-0.3-2.1-1c-0.6-0.7-0.9-1.5-0.9-2.6c0-1.1,0.3-1.9,0.9-2.6c0.6-0.7,1.3-1,2.1-1
            c0.8,0,1.5,0.3,2.1,1c0.6,0.7,0.9,1.5,0.9,2.6C405,199.2,404.7,200,404.1,200.7z"/>
        </g>
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter_2_" filterUnits="userSpaceOnUse" x="371.8" y="187.5" width="36.9" height="17.8">
          
            <feColorMatrix  type="matrix" values="-1 0 0 0 1  0 -1 0 0 1  0 0 -1 0 1  0 0 0 1 0" colorInterpolationFilters="sRGB" result="source"/>
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="371.8" y="187.5" width="36.9" height="17.8" id="SVGID_13_">
        <g className="st21">
          
            <image style={imgStyle} width="42" height="23" xlinkHref={require('../../assets/35EF3E805A050906.jpg')} transform="matrix(1 0 0 1 369.5912 184.5279)">
          </image>
        </g>
      </mask>
      <g className="st22">
        <g>
          <path className="st23" d="M396,191.1h-3.1c-0.6,0-1.1,0.1-1.4,0.3c-0.4,0.2-0.6,0.6-0.8,1.1l-2.8,8.1l-2.7-8.1
            c-0.2-0.5-0.5-0.9-0.8-1.1c-0.3-0.2-0.8-0.3-1.4-0.3h-2.6h-0.4h-2.4v-3.6h-3.9v3.6h-1.8v3.3h1.7v5.5c0,0.9,0.1,1.7,0.4,2.4
            c0.3,0.7,0.7,1.2,1.1,1.6c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.1,0.4,1.5,0.4c0.4,0.1,0.9,0.1,1.4,0.1c0.4,0,0.8,0,1.1-0.1l0.2,0
            v-3.5l-0.9,0.1c-0.6,0-1.2-0.2-1.7-0.5c-0.5-0.3-0.7-0.9-0.7-1.7v-5.1h2.4v0l0.3,0h0.2v0l1,0c0.2,0,0.4,0.1,0.5,0.3l3.9,10.5
            h2.7c0.6,0,1.1-0.1,1.4-0.3c0.4-0.2,0.6-0.6,0.8-1.1l1.7-4.5l1.6-4.3c0.1-0.2,0.2-0.3,0.5-0.3h1.6V191.1z"/>
          <path className="st23" d="M406.7,193c-1.3-1.3-2.9-2-4.7-2c-1.8,0-3.4,0.7-4.7,2c-1.3,1.3-1.9,3.1-1.9,5.1c0,2.1,0.6,3.8,1.9,5.1
            c1.3,1.3,2.8,2,4.7,2c1.9,0,3.4-0.7,4.7-2c1.3-1.3,1.9-3.1,1.9-5.1C408.6,196,408,194.3,406.7,193z M404.1,200.7
            c-0.6,0.7-1.3,1-2.1,1c-0.8,0-1.5-0.3-2.1-1c-0.6-0.7-0.9-1.5-0.9-2.6c0-1.1,0.3-1.9,0.9-2.6c0.6-0.7,1.3-1,2.1-1
            c0.8,0,1.5,0.3,2.1,1c0.6,0.7,0.9,1.5,0.9,2.6C405,199.2,404.7,200,404.1,200.7z"/>
        </g>
      </g>
    </g>
    <g>
      <defs>
        <path id="SVGID_14_" d="M406.7,193c-1.3-1.3-2.9-2-4.7-2c-1.8,0-3.4,0.7-4.7,2c-1.3,1.3-1.9,3.1-1.9,5.1c0,2.1,0.6,3.8,1.9,5.1
          c1.3,1.3,2.8,2,4.7,2c1.9,0,3.4-0.7,4.7-2c1.3-1.3,1.9-3.1,1.9-5.1C408.6,196,408,194.3,406.7,193z M404.1,200.7
          c-0.6,0.7-1.3,1-2.1,1c-0.8,0-1.5-0.3-2.1-1c-0.6-0.7-0.9-1.5-0.9-2.6c0-1.1,0.3-1.9,0.9-2.6c0.6-0.7,1.3-1,2.1-1
          c0.8,0,1.5,0.3,2.1,1c0.6,0.7,0.9,1.5,0.9,2.6C405,199.2,404.7,200,404.1,200.7z"/>
      </defs>
      <clipPath id="SVGID_15_">
        <use xlinkHref="#SVGID_14_"  style={imgStyle}/>
      </clipPath>
      <g className="st24">
        
          <image style={imgStyle} width="870" height="887" xlinkHref={require('../../assets/CB94088D02BA4E39.png')} transform="matrix(4.106477e-02 0 0 4.106477e-02 387.993 182.3155)">
        </image>
      </g>
    </g>
</g>

    );
  }
}

export default tvo;