export default {
	label: 'Map of Belgium',
	viewBox: '-80 0 1028 500',
	locations: [{
		id: 'Limburg',
		name: 'Limburg',
		path: 'M873.8 308.5l4.9 6.2-6 9.3-2.3 1-6.2-3.3-9.3 1.4-10.9-14.6-6.7 1.8-4.5-4.9 2.8-4.2 2.3-1.8 2.6 1 5.3 5.9 2.6 1.8 22.5-0.6 2.9 1z m-60.6-23.1l-12.6 12.8-22.5 6.7 0.5 6.6-7 6.2-4.1 1-5.1-5.6-2 7.2-5.8 0.6-7-2.7-3.2-8-3.5-1.6-11.9 4.1-5.2 4.6-1.2 4.6-8.3-4.5-8.3 5.2-1.9-4.7-1.9 3.9-15.4-2 2.9 6.7-3.3 4.1-17.3-5.1 5-10.8-5-3 3.5-8.3-2.1-7.5 11.7-12.2-5.7-2 1.8-13.8 4.3-12.1 5.1 1.8 1.2-1.3 2.3-11.4-3.8-3.3-9.1-0.1-0.9-3.3-10 4.8-4.3-4.8-4.3 1.9-1.7-1.3-3.4-9 10.4-9.3 0.2-9.1 10.9-5-1.7-9.6 2.5-0.7 1.4 3.3 6.5-2.7-7-9.1-5.7-2.6-7.3 1 0 10.3-4.6 2.9-5.3-3.4-5.2 2.1-4.2-8.2-6.6 1.8 7.5-10.4 0-5.7 20.7-4.1 10.5-13.4 13-0.7 5.8-8.6 8.4 2.5 6.5-5.8-0.8-16.7-10.6-9.9 2.9-15.8 3.1-2.6 8.1-2 30.6 1.3 5.4-2 10.9-7.7 5.1-2.5 5.6 0.7 6.1 3.8 4.8 6.3 1.8 8 0.9 9.4 5.9 5.1 14.6 4.4 3.6 2.5 2.4 2.9 2.9 2 4.4 0 10.8-2.3 3.4 0.4 4.2 2.5 5.4 7.5 3.5 2.9 7.9-2 4.4 0.4 3 7.2-3.7 3.6-0.8 4.7 5.9 6.4-2.4 1.6-2.6 1-2.8 0.5-2.8-0.3 1.7 5.9-1.9 5.6-1.4 2.9-2 2.7-3-2.6-2.1 1.3-1.3 4-0.6 5.5 3.5 4.8-4.1 10-10.2 16.4 5.7-1.6 2.5 0.1 2.6 1.5-3 5-4.5 9.5-3.3 5.1-1.3 1-6 2.3-18.6 20.9 0.5 9.2 4.2 6.2 4.3 3.1z'
	}, {
		id: 'Bruzz',
		name: 'Bruzz',
		path: 'M492.8 244.9l4.1 7-0.1 3.9-4.1 4.2 10.4 6.7 4.1 13-5.5 0.7-2.1 3.6 8.6 6.4-21.1 11.3-5.5 1.3-5.7-1-7.1-4.9-6.9-14.5-4.7 2.5-9.7-5.5 3.1-6.2 6.6-1.1 1.6-6.9-2.6-4.7 4.9-9.7 9.5-4.8 11.1 1.9 6.7-6.7 4.4 3.5z'
	}, {
		id: 'Vlaams-Brabant',
		name: 'Vlaams-Brabant',
		path: 'M669.1 325.5l-6.8 1.1-3.9-1.9-4.3-5.5 0.1-7.3-6.3-3.7-9.8-7.8-12.7 10.1-3.9 0.6-3.3-2.7 0.9-7.4-19.5 2 1.1-4.4-6.2-0.6-5.6-9.3-10.8-3.7-6.9 2.5-2.3 5.2-1-3.1-4.6 2.6-13.5-3.5-1.1 10.5 4.8 6.4-5 5.8-8.7 0.5-1.4-8.9-6.1 6.7-11.3 2.6-0.6 5.1-8.2-4.8-1.3-2 1.1-4.7-3.7-0.6-5.7 1.8-9.4 7.4-12.5 1.9-1.5 6.2-4.9-0.4-4.9-6.8-1.9 2.2 2.7 4.1-5.6 0.1-0.7 6.2-1.8 2.2-3.8 1.2-7.2-2.4-5 3.7-8.5-7-7 0.2-5.6-7.8-2.5-1.1-2.6 1-1.8 4.5-6.3-0.1-2.1 4.1-7-2.3-3.6 6.9-1.9-2.8-6 3.2-5-1.6-5.2 4.2-15.7-0.5-5.5-2-3.7-4.8-1.2-4.5 1.8-6.8 6 2.2 8.4-2.2 0.4-2.2-5.4-7.4 3.3-7.3 3.2-1.8 6.7 5.3 4.4-5.6 3.7 3.4 4.4-1.8 7.7-6.6 3.8-11.5-2.3-6-6.6-1.3 12.1-17.6 0.2-4.3-3.7-3.3 6.8-5.2-2.3-7.9 11.9 5 6-4.2 0.1-5.2-3.6-11.5 3.9-8.3 8.4 3 5.9-2.8 6.1-19.7 8.8-2.3 6.3 1.1 12.8 5.7-1.8 3 1.7 0.8 8.1-5.4-2.5 6.2 4.9 1.5 1.2 6.1 15.8-3.8 5.7 5.9 4.7-7.1 8.8 6.7 4.7-4.4 8.2 4.8 4 0.1 3.4-8.4 18.9-5.1 7 3.8-3 6.6 10.1-11.4 5.7 1.2 8.5-6.4 4.4 9.7 3.3 2.2 13.5-5.6 3.2 1.6 7-6.2 10-4.1 4.8-0.3 3.6 4.3 6.6-1.8 4.2 8.2 5.2-2.1 5.3 3.4 4.6-2.9 0-10.3 7.3-1 5.7 2.6 7 9.1-6.5 2.7-1.4-3.3-2.5 0.7 1.7 9.6-10.9 5-0.2 9.1-10.4 9.3 3.4 9 1.7 1.3 4.3-1.9 4.3 4.8 10-4.8 0.9 3.3 9.1 0.1 3.8 3.3-2.3 11.4-1.2 1.3-5.1-1.8-4.3 12.1-1.8 13.8 5.7 2-11.7 12.2 2.1 7.5-3.5 8.3 5 3-5 10.8z m-176.3-80.6l-4.4-3.5-6.7 6.7-11.1-1.9-9.5 4.8-4.9 9.7 2.6 4.7-1.6 6.9-6.6 1.1-3.1 6.2 9.7 5.5 4.7-2.5 6.9 14.5 7.1 4.9 5.7 1 5.5-1.3 21.1-11.3-8.6-6.4 2.1-3.6 5.5-0.7-4.1-13-10.4-6.7 4.1-4.2 0.1-3.9-4.1-7z'
	}, {
		id: 'Oost-Vlaanderen',
		name: 'Oost-Vlaanderen',
		path: 'M461.3 95.1l-1 0.7-1.6 4.1 5.5 17.8-2.3 1.5 6.3 9 2 8.2-1.2 9-3.2 6.5-10.3 3.4-10.5 0-5.2 1-4.6 3.2-3.3 3.9-0.8 1.8 1.4 9.3 1.5 4.6 0.8 4.8-1.3 4.1 2.5 3 2.9 1.3 5.1-4.2 2.2 2.6-6.1 19.7-5.9 2.8-8.4-3-3.9 8.3 3.6 11.5-0.1 5.2-6 4.2-11.9-5 2.3 7.9-6.8 5.2 3.7 3.3-0.2 4.3-12.1 17.6 6.6 1.3 2.3 6-3.8 11.5-7.7 6.6-4.4 1.8-3.7-3.4-4.4 5.6-6.7-5.3-3.2 1.8-3.3 7.3 5.4 7.4-0.4 2.2-8.4 2.2-6-2.2-2.7-7.1-14.6 3.3-3.5-3.5-10.4 1.2-4.3-12.8-11.2 4.8-2.8-5.6-7.4 4.2-10.5 20.5-7.3-5-17.1-0.3-1.7-12.9-10.3 3.1-9.5-4 16.2-12 0.6-3-6-8.2 3.4-4.2-16-13.9 6.1-6.3-2.1-7.7-4-1.7-6 3.7-2.2-5.6 3.5-3.5-2-4.7 6.1 0.9 1.8-1.8-3.9-4 5.2 0.1-12.5-6.2 8-3.7-4.4-6 6.8-10.1-7.8-13.4 5.6-4-29.3-21 11.4-7.4 9.3-17.8-6.1-10.8-7-2.3 6.3-4.4-4.5-10.8 8.6-5.4-0.3-0.8 6 2.8 8.2 0.9 12.3-1.9 0.8-3.9-0.7-5.3 0.5-4.7 2.8-3 3.8-0.9 4.2-0.3 10.3-2.3 6.4-0.4 6.6 1.1 31.6 10.6 4.9 3.2 1.4 5.4-0.4 4.6 0.4 4.3 3.8 4.6 3.3 1.5 30.6-0.4 5.8-1.9 11.8-9.3 32.1-16.2 12.9-10.1 10.2-14 1.4-4.1 2.3-11.3 4.9 4.6 2 4.1 1.9 7.6 4.2 5.2 4.7 4.1 3.2 4.1 1.3 5.2-0.7 2.9-1.7 2.9z'
	}, {
		id: 'West-Vlaanderen',
		name: 'West-Vlaanderen',
		path: 'M228.2 105.4l0.3 0.8-8.6 5.4 4.5 10.8-6.3 4.4 7 2.3 6.1 10.8-9.3 17.8-11.4 7.4 29.3 21-5.6 4 7.8 13.4-6.8 10.1 4.4 6-8 3.7 12.5 6.2-5.2-0.1 3.9 4-1.8 1.8-6.1-0.9 2 4.7-3.5 3.5 2.2 5.6 6-3.7 4 1.7 2.1 7.7-6.1 6.3 16 13.9-3.4 4.2 6 8.2-0.6 3-16.2 12-11.6 8.7-11.7 12.3-1.1 3.1-4-0.9-6.4-4.7 0.4-5.6-5.6-7.9-11 1.6-10.6-6.1-11.6 6-3.2 1.1-4.3-7.7-4.3-4.2-6.8-2.1-20.6 6.5-2.4-15.1-4-2.6-15.1 6.1-0.2 1.8 5.3 6.3-9.8 4-1.1 3.8-7.4 1.5-3.7-3.4-1.7 0.5-6.9 4.2 5.3 16.9 1 3.9-20.7-9.5-4.8-4-1.3-2.6-3.5-8.2-1.5-2.7-9.6-9.3-1.7-3.1-0.8-2.7-1.3-2.4-3.4-1.8-2.5-0.3-4.8 0.7-5.9-0.9-1.9-0.8-1.4-2.4-2.2-5.2-1.2-1.3-3.1-1.6-0.9-1.4 0.3-1.9 2-4.6 0.3-2.6-5.1-18 1.5-3.8 5.9-6 1.6-2.5-0.9-8.1-4-6.2-9.3-10.3-2.6-8-2.6-18.1-3.9-9.5 5.2-3.9 9.9-3.2 35.1-26.7 53.9-31.9 52.1-34.4 58.2-18.9 2.2 16-1 10.2-0.2 9.7 3.6 10.4 6.3 6.9 1.9 0.9z'
	}, {
		id: 'Antwerpen',
		name: 'Antwerpen',
		path: 'M705 100.3l-3.1 2.6-2.9 15.8 10.6 9.9 0.8 16.7-6.5 5.8-8.4-2.5-5.8 8.6-13 0.7-10.5 13.4-20.7 4.1 0 5.7-7.5 10.4-3.6-4.3-4.8 0.3-10 4.1-7 6.2-3.2-1.6-13.5 5.6-3.3-2.2-4.4-9.7-8.5 6.4-5.7-1.2-10.1 11.4 3-6.6-7-3.8-18.9 5.1-3.4 8.4-4-0.1-8.2-4.8-4.7 4.4-8.8-6.7-4.7 7.1-5.7-5.9-15.8 3.8-1.2-6.1-4.9-1.5 2.5-6.2-8.1 5.4-1.7-0.8 1.8-3-12.8-5.7-6.3-1.1-8.8 2.3-2.2-2.6-5.1 4.2-2.9-1.3-2.5-3 1.3-4.1-0.8-4.8-1.5-4.6-1.4-9.3 0.8-1.8 3.3-3.9 4.6-3.2 5.2-1 10.5 0 10.3-3.4 3.2-6.5 1.2-9-2-8.2-6.3-9 2.3-1.5-5.5-17.8 1.6-4.1 1-0.7 2.5-0.7 2.1-2.3 3.7-8-8.7-3.8-2-1.6-1.1-3.7 0.2-9-0.8-4.3-1.9-3.3-3.8-4.7 7.6 0.3 9.3 5.1 5 1.7 12.1 0.8 4.9-2.6 1.3-7.2-1.6-4.1-5.8-6.9-2-3.8 0.2-4.2 1.7-3.2 0.5-3-3.1-3.4 13.5-7.9 13.9-5.2 7.7-1.3 3.3 0.6 2 3.5-0.3 7-2.2 5.1-0.5 4.1 5 3.6 3.4 0.3 7.4-1.4 8.3 2.1 4-0.3 3.5-1.3 2.7-2 20-24.7 8.6-4.4 3.7 0.4 4.7 1.8 4.4 2.8 2.6 3.6 0.6 6.2-1.6 6-1.2 6.3 1.9 7.2-11.3-5.3-2.9 0.5-1.5 4.9 5.5 2.4 18.1 0.7 4.8 1.2 10 4.7 5.6-1.6 6.5-6.4 6-8.1 4.1-6.7 1.8-5.1 0.8-3.9 1.6-2.5 4-1 0.7 1 7.8 5.2 5.1 9.7-1 6.9-2.9 6.6-0.9 9 1.7 4.1 8.9 9.9 1.4 3.6 2.6 9.1 1.3 2.7 4.5 2.5 10-0.9 4.8 0.5 4.5 5.8 0.1 14.8 4.6 1.1 1.7-0.4z'
	}, {
		id: 'ATV',
		name: 'ATV',
		path: 'M466.5,163.1c0.9-0.2,3.7,1.7,3.7,1.7l2.4,2.2h4.8v4.2l2.3,2.1h16.7l3.6-2.6l2.1,2.6h4.6v-8.5l3.5-4.9h9.4l3.4-7.2l2.5-2.2l-4.3-2.6l0.1-6.3l11.2-4.8l0.5,8.4l4.9-0.8l12.6-10.6h15.5l1-7.5h4.8l2.1-5.8v-12.1h5l6.4-3.1c0,0-0.5-6.9,0-7.4c0.5-0.5,3-2.7,3-2.7v-3.4l-2.2-1.2l-0.9-4.1l3.6-6.8l-17.6-7.9l1.2-4.9c0,0,2.6-1.7,2.5-2.1c-0.1-0.4-0.4-5.6-0.4-5.6l-3.8-4.9L570,38l-10.4-5.9l-5.1-3.3l0,0l-1.1,1.4l-2.7,2l-3.5,1.3l-4,0.3l-8.3-2.1l-7.4,1.4l-3.4-0.3l-5-3.6l0.5-4.1l2.2-5.1l0.3-7l-2-3.5l-3.3-0.6l-7.7,1.3l-13.9,5.2l-13.5,7.9l3.1,3.4l-0.5,3l-1.7,3.2l-0.2,4.2l2,3.8l5.8,6.9l1.6,4.1l-1.3,7.2l-4.9,2.6l-12.1-0.8l-5-1.7l-9.3-5.1l-7.6-0.3l3.8,4.7l1.9,3.3L458,66l-0.2,9l1.1,3.7l2,1.6l8.7,3.8l-3.7,8l-2.1,2.3l-2.5,0.7l-1,0.7l-1.6,4.1l5.5,17.8l-2.3,1.5l6.3,9l2,8.2l-1.2,9l-3.2,6.5l-2.3,0.8v0C463.5,152.7,465.6,163.3,466.5,163.1z'
	}, {
		id: 'ROB',
		name: 'ROB',
		path: 'M539.8,311.9l8.7-0.5l5-5.8l-4.8-6.4l1.1-10.5l13.5,3.5l4.6-2.6l1,3.1l2.3-5.2l6.9-2.5l10.8,3.7l5.6,9.3l6.2,0.6l-1.1,4.4l19.5-2l-0.9,7.4l3.3,2.7l3.9-0.6l12.7-10.1l9.8,7.8l6.3,3.7l-0.1,7.3l4.3,5.5l3.9,1.9l6.8-1.1l0,0l5-10.8l-5-3l3.5-8.3l-2.1-7.5l11.7-12.2l-5.7-2l1.8-13.8l4.3-12.1l5.1,1.8l1.2-1.3l2.3-11.4l-3.8-3.3l-9.1-0.1l-0.9-3.3l-10,4.8l-4.3-4.8l-4.3,1.9l-1.7-1.3l-3.4-9l10.4-9.3l0.2-9.1l10.9-5l-1.7-9.6l2.5-0.7l1.4,3.3l6.5-2.7l-7-9.1l-5.7-2.6l-7.3,1v10.3l-4.6,2.9l-5.3-3.4l-5.2,2.1l-4.2-8.2l-6.6,1.8l-3.6-4.3l-4.8,0.3l-10,4.1l-7,6.2l-3.2-1.6l-13.5,5.6l-3.3-2.2l-4.4-9.7l-8.5,6.4l-5.7-1.2l-10.1,11.4l3-6.6l-7-3.8l-18.9,5.1l-3.4,8.4l-4-0.1l-8.2-4.8l-4.7,4.4l0,0l-4.9,6.1l2.4,5.2l8,1l2.2,1.7l3.6,1l5.2-2.5l5.4,4.9l-2.1,4.3v2.4h-3.1l-12,8.1l-3.3,3.4l-1.1,3.9l-5.3,3.8l2.4,9.8l-0.2,4.8l3.1,3.6l-3.4,1.5l-10.6,7.5c0,0-0.3,0-0.8,0.1l-0.6,0.1c-1.3,0.2-3.1,0.4-3.8,0.5l-0.3,0l-2.1,3.6l5.3,3.9l18.2,1.2l4.4-1.2h2.7l-1.8,2.4l-2.2,2.8l2.2,4.2h8.1l0.5,5.2l0.4,1.6l1.1-1.2L539.8,311.9z'
	}, {
		id: 'TVO',
		name: 'TVO',
		path: 'M354,308.4l2.7,7.1l6,2.2l8.4-2.2l0.4-2.2l-5.4-7.4l3.3-7.3l3.2-1.8l6.7,5.3l4.4-5.6l3.7,3.4l4.4-1.8l7.7-6.6l3.8-11.5l-2.3-6l-6.6-1.3l12.1-17.6l0.2-4.3l-3.7-3.3l6.8-5.2l-2.3-7.9l11.9,5l6-4.2l0.1-5.2l-3.6-11.5l3.9-8.3l8.4,3l5.9-2.8l6.1-19.7l-2.2-2.6l-5.1,4.2L436,191l-2.5-3l1.3-4.1l-0.8-4.8l-1.5-4.6l-1.4-9.3l0.8-1.8l3.3-3.9l4.6-3.2l5.2-1h10.5l10.3-3.4l3.2-6.5l1.2-9l-2-8.2l-6.3-9l2.3-1.5l-5.5-17.8l1.6-4.1l1-0.7h0l1.7-2.9l0.7-2.9l-1.3-5.2l-3.2-4.1l-4.7-4.1l-4.2-5.2l-1.9-7.6l-2-4.1l-4.9-4.6l-2.3,11.3l-1.4,4.1l-10.2,14l-12.9,10.1l-32.1,16.2l-3.8,3v3.8l3.4,1.7l-1.7,3l-2.5,0.2v3l2.1,4.4v10.1h-5.4l-2.3,0.6l-0.6,4.6l-8.2,4.7h-2.6l-1-4.9h-3v19.1l0.9,4.4l4,3.9l1.2,1.6l-0.7,3.2l-6.9,1.3l-7.6,5.1h-3.5l-4.7-2.7l-1.2,3.2v21.9v11.7l6.9,4.9c0,0-2.3,1.5-1.9,2.1c0.3,0.6,0.7,4.2,0.7,4.2s1.2,0.6,1.2,0.9c0,0.4,0,2.6,0,2.6l-6.3,1.2l-0.5,7.8l-2.2,1.4l-0.7,4.8c0,0-2.3-0.4-2.9,0c-0.6,0.4-7.8-6.4-8.2-6.5c-0.4-0.1-4.2-0.7-4.7-0.6c-0.5,0.1-1.9,1.6-1.9,1.6v4.1l2.5,1.4l-0.7,3.8c0,0,1.8,0.9,1.8,1.3s0,7.5,0,7.5s4,1.9,3.9,2.3c-0.1,0.4-3.4,1.5-3.4,1.5s-1.8,3.3-1.6,3.6c0.1,0.4,2,2.1,2,2.1h5l0.8,11.2c0,0,1.8,1.8,2,1.4c0.2-0.4,4.1-4.7,4.1-4.7l4.1-2.2l2.1-1.9c0,0,0.2-3.7,0.5-3.7s1.8,0,2.6,0.1c0.8,0.1,2.3,2.2,2.3,2.2l0.4,7.6l6.9,0.8l-1.1,4.8l-5,7.7c0,0-4.6,1-4.9,1.3c-0.4,0.3-9.5,0-9.5,0l-0.8,5.2l3.7,5.3l3.8,7.3l0.1,0.1L354,308.4z'
	}, {
		id: 'FOCUS',
		name: 'FOCUS',
		path: 'M36.7,233.2 44.6,221.8 47.4,218.1 57.2,218.1 57.2,230.1 60.5,235 57.2,239.3 58.6,240.8 65.5,236.9 70.5,240 73.9,235.3 71.8,230.9 74.3,228.2 78.4,234.1 81.7,230.6 90.8,230.6 108.9,219.3 109.3,211.8 116.7,209.6 120.7,206.6 124,204.5 127,197.3 144.3,197.3 145.3,192.3 152.5,190.3 156,183.6 156,178.7 160.6,175.3 164.6,178.7 181.7,178.7 183.1,167.6 188.4,164.8 192.7,164.8 210.5,165.1 221.9,157.7 231.2,139.9 225.1,129.1 218.1,126.8 224.4,122.4 219.9,111.6 228.5,106.2 228.2,105.4 228.2,105.4 226.3,104.5 220,97.6 216.4,87.2 216.6,77.5 217.6,67.3 215.4,51.3 157.2,70.2 105.1,104.6 51.2,136.5 16.1,163.2 6.2,166.4 1,170.3 4.9,179.8 7.5,197.9 10.1,205.9 19.4,216.2 23.4,222.4 23.7,224.9 33.5,233.2'
	}, {
		id: 'RTV',
		name: 'RTV',
		path: 'M582.4,179.5l4.4-5l0.3-3.5h8.3v-3.3l-3.4-7l-4-3.9l-5.5,0.8l0.8,3.1l-8.6-0.8l-5.6-3.4l3.6-10.4l-3.5-2.3v-6.4l-3.7-3.2l-0.3-0.3h-14.5L538,144.4l-4.9,0.8l-0.5-8.4l-11.2,4.8l-0.1,6.3l4.3,2.6l-2.5,2.2l-3.4,7.2h-9.4l-3.5,4.9v8.5h-4.6l-2.1-2.6l-3.6,2.6h-16.7l-2.3-2.1v-4.2h-4.8l-2.4-2.2c0,0-2.8-1.9-3.7-1.7s-3-10.4-3-10.4v0l-8,2.6H445l-5.2,1l-4.6,3.2l-3.3,3.9l-0.8,1.8l1.4,9.3l1.5,4.6l0.8,4.8l-1.3,4.1l2.5,3l2.9,1.3l5.1-4.2l2.2,2.6l8.8-2.3l6.3,1.1l12.8,5.7l-1.8,3l1.7,0.8l8.1-5.4l-2.5,6.2l4.9,1.5l1.2,6.1l15.8-3.8l5.7,5.9l4.7-7.1l8.8,6.7l4.7-4.4l8.2,4.8l4,0.1l3.4-8.4l18.9-5.1l7,3.8l-3,6.6l10.1-11.4l5.7,1.2l8.5-6.4l0,0l2.1-6.6L582.4,179.5z'
	}]
};
